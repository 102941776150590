// Custom (non-bootsrap) Project Styles - must be imported first in case of SASS
@import "connectiv/magnific-popup.scss";
@import "connectiv/z-index.scss";
@import "connectiv/sprite.scss";
@import "connectiv/custom-vars.scss";
@import "connectiv/contactpersons.scss";
//@import "connectiv/conn-basics.scss";

// Font-Awesome
// Wenn nur einzelne Icons verwendet werden, diese als SVG extrahieren (z.B. mit der Chrome-App "IcoMoon")
@import "font-awesome/font-awesome.scss";

//Bootstrap
//enthält in dieser Einstellung nur häufig benötigte Dateien (bei Bedarf zusätzliche Kompontenten in der _bootstrap.scss einkommentieren)
@import "bootstrap/bootstrap";

//Slick Slider
@import "slick/slick.scss";
.slick-slide:focus { outline: none; }
.slick-arrow { position: absolute; border: none; background: transparent; font-size: 0; width: 44px; height: 44px; line-height: 40px; background-repeat: no-repeat; background-position: center; top: 50%; margin-top:-22px; z-index: 100; transition: all 0.3s; outline: none;}
.slick-arrow:hover { opacity: 1; }
.slick-prev { left: 0; }
.slick-prev:before { font-family:FontAwesome;content:"\f104";font-size:48px;color:#FFF; }
.slick-next { right: 0; }
.slick-next:before { font-family:FontAwesome;content:"\f105";font-size:48px;color:#FFF; }
.slick-dots { position: absolute; width: 100%; text-align: center; padding: 0; bottom: 0; }
.slick-dots li { list-style-type: none; display: inline-block; padding:0 5px;}
.slick-dots li:before { content: none!important; }
.slick-dots li button { outline: none; border: none; width: 10px; height: 10px; padding: 0; border-radius: 100%; font-size: 0; background-color: #fff; opacity: 1; box-shadow: 0px 0px 3px 1px #000;}
.slick-dots li.slick-active button { background-color:$brand-primary; opacity: 1; }
.slick-dots li button:hover { opacity: 0.75; }
.content-slider .slick-prev { left: -28px }
.content-slider .slick-next { right: -28px }
.content-slider { margin: 0 -$grid-gutter-width/2; }
.content-slider--item { margin: 0 $grid-gutter-width/2; }

//Animatiertes Menüicon
.menu-icon { width: 38px; height: 38px; position: relative; transform: rotate(0deg); transition: .5s ease-in-out; cursor: pointer; background: none; border: none; padding: 0 3px; }
.menu-icon span { display: block; position: absolute; height: 4px; width: 100%; background: $brand-primary; opacity: 1; left: 0; transform: rotate(0deg); transition: .25s ease-in-out; }
.menu-icon span:nth-child(1) { top: 5px; }
.menu-icon span:nth-child(2),.menu-icon span:nth-child(3) { top: 17px; }
.menu-icon span:nth-child(4) { top: 29px; }
.menu-icon.open span:nth-child(1) { top: 18px; width: 0; left: 50%; }
.menu-icon.open span:nth-child(2) { transform: rotate(45deg); }
.menu-icon.open span:nth-child(3) { transform: rotate(-45deg); }
.menu-icon.open span:nth-child(4) { top: 18px; width: 0; left: 50%; }
.menu-icon:focus { outline: none; }


nav ul { margin: 0; padding: 0; }
nav ul li { position: relative;list-style-type: none; margin: 0; padding: 0; }

@mixin font-size($sizeValue: 1.6) {
  font-size: ($sizeValue * 10) + px;
  font-size: $sizeValue + rem;
}


/* pt-sans-regular - latin */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/pt-sans-v9-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('PT Sans'), local('PTSans-Regular'),
  url('../fonts/pt-sans-v9-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/pt-sans-v9-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/pt-sans-v9-latin-regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/pt-sans-v9-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/pt-sans-v9-latin-regular.svg#PTSans') format('svg'); /* Legacy iOS */
}
/* pt-sans-700 - latin */
@font-face {
  font-family: 'PT Sans';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/pt-sans-v9-latin-700.eot'); /* IE9 Compat Modes */
  src: local('PT Sans Bold'), local('PTSans-Bold'),
  url('../fonts/pt-sans-v9-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/pt-sans-v9-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
  url('../fonts/pt-sans-v9-latin-700.woff') format('woff'), /* Modern Browsers */
  url('../fonts/pt-sans-v9-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
  url('../fonts/pt-sans-v9-latin-700.svg#PTSans') format('svg'); /* Legacy iOS */
}


/* ==========================================================================
   Project's custom styles
   ========================================================================== */

.no-display {
  display: none;
}

#captcha {
  background-repeat: no-repeat;
}

$mobile-menu-height: 60px;

.container-fluid {
  max-width: 1000px + $grid-gutter-width;
}

*:focus {
  outline-color: $brand-primary;
}

.righter {
  margin-left: 40px;
}

body {
  @include font-size(1.4);
  font-family: 'PT Sans', sans-serif;
  overflow-x: hidden;
}

h1, h2, h3, h4 {
  margin-top: 0;
  color: $brand-primary;
}

h1 {
  @include font-size(3.6);
}

h2 {
  @include font-size(2.4);
  margin-bottom: 15px;
}

h3 {
  @include font-size(1.6);
}

p {
  @include font-size(1.4);
}

//.start p {
//  @include font-size(1.6);
//}

input, select, textarea {
  border-radius: 0!important;
  box-shadow: none!important;
}

#content img {
  max-width: 100%;
}

header {
  position: relative;
  height: $mobile-menu-height;
}

section.text {
  margin: 30px 0;
}

.logo {
  display: block;
  position: relative;
  padding: 10px 0 10px 0;
  max-height: 60px;
}

.logo img {
  max-height: 40px;
}

.logo-full {
  display: none;
}

.menu-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 15px 0 0;
}

.btn {
  line-height: 1.42857143; //fixes SASS number precision issue
  border-radius: 0;
  background-color: $brand-primary;
  color: #FFF;
  margin-top: 10px;
}

.btn:hover {
  color: #FFF;
}

.search {
  position: relative;
  z-index: 1;
}

.search form {
  margin: 10px;
}

.search input {
  width: 100%;
  height: 44px;
  border: 1px solid #f1f1f1;
  padding: 0 50px 0 10px;
  transition: all 0.5s;
  outline-color: transparent!important;
}

.search input:focus,
.search input:active {
  border: 1px solid #727272;
  outline-color: transparent!important;
}

.search--btn {
  position: absolute;
  top: 0;
  right: 10px;
  padding: 0;
  height: 44px;
  width: 44px;
  border: 0;
  background-color: transparent!important;
  font-size: 24px;
  color: $brand-gray;
}

.search--btn img {
  height: 24px;
}

.search .contact {
  display: none;
}

.nav-top {
  height: 100%;
}

.nav0-1 {
  display: none;
  position: absolute;
  background-color: #727272;
  top: $mobile-menu-height;
  left: 0;
  right: 0;
  z-index: 200;
  width: 100%;
  padding: 10px 0;
}

.nav0-1 a {
  position: relative;
  @include font-size(1.8);
  display: block;
  color: #FFF;
  padding: 10px 10px;
  margin-right: 60px;
}

nav ul li div {
  display: none;
}

nav ul li div.active {
  display: block;
  background-color: lighten($brand-gray, 5%);
  padding: 0 0 0 20px;
}

.dropdown-icon {
  position: absolute;
  display: block;
  width: 60px;
  height: 44px;
  right: 0;
  top: 0;
  transition: all 0.3s;
  text-align: center;
}

.dropdown-icon.rotate {
  transform: scaleY(-1);
}

.dropdown-icon img {
  fill: #FFF;
  transition: transform 0.3s;
}

.global .lang-select {
  margin-top: 24px;
  float: left;
}

.global .lang-select ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.global .lang-select ul li {
  margin: 0;
  padding: 0;
  float: left;
  line-height: 13px;
}

.global .lang-select ul li a {
  color: $brand-gray;
}

.global .lang-select a:hover,
.global .lang-select a:focus,
.global .lang-select a:active {
  color: $brand-primary;
}

.global .lang-select ul li a.active {
  color: #000;
  font-weight: bold;
}

.global .lang-select ul li:not(:last-of-type) {
  border-right: 1px solid $brand-gray;
  padding-right: 5px;
  margin-right: 5px;
}

.slider--item {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 250px;
  overflow: hidden;
}

.slider--item .container {
  position: relative;
  height: 100%;
}

.slider-overlay {
  background-color: $brand-primary;
  color: #FFF;
  font-size: 16px;
  display: block;
  padding: 5px 15px 5px 15px;
  position: absolute;
  bottom: 30px;
  right: 0;
}

.slider-overlay:after {
  content: "";
  height: 100%;
  background: $brand-primary;
  width: 2000px;
  width: 100vw;
  left: 100%;
  top: 0;
  display: block;
  position: absolute;
  margin-left: -1px;
}

.cboxes {
  position: relative;
  //background-color: #FFF;
  margin: 0 auto 0 auto;
  padding: 20px 0 0 0;
}

.cboxes--subcats {
  margin-bottom: 20px!important;
}

.cboxes--subcats .cboxes-item {
  max-height: 150px;
}

.cboxes-item {
  display: block;
  position: relative;
  width: 100%;
  max-width: 300px;
  height: 175px;
  height: 45vw;
  margin: 0 auto 30px auto;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.cboxes-item span {
  display: block;
  min-width: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: transparentize($brand-primary,0.2);
  font-size: 16px;
  color: #FFF;
  padding: 6px 15px;
  transition: all 0.3s;
}

.cboxes-item:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: transparentize($brand-primary,0.5);
  transition: all 0.3s;
}

.cboxes-item:hover:before {
  width: 100%;
  height: 100%;
}

.cboxes-item:hover span {
  font-size: 20px;
}

.listboxes-item {
  position: relative;
  margin-bottom: 50px;
}

.listboxes-image {
  width: 100%;
  height: 50vw;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border: 1px solid $brand-primary;
}

.listboxes-text {
  border: 2px solid $brand-primary;
  border-top: none;
  padding: 20px 10px 10px 10px;
  color: #FFF;
  background-color: $brand-primary;
}

.listboxes-text h2 {
  color: #FFF;
}

.responsive-video-embed {
  position: relative;
  height: 0;
  padding-bottom: 56.25%;
  margin-bottom: 100px;
}

.responsive-video-embed iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

footer {
  padding: 35px 0;
  background-color: #727272;
}

.footer-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer-nav a {
  color: #FFF;
  display: block;
  padding: 8px 10px;
}

.footer-nav li {
  text-align: center;
}

.slimslider--item {
  background-size: cover;
  background-position: 30% center;
  background-repeat: no-repeat;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.slimslider--item .container {
  position: relative;
  height: 100%;
}

.slimslider-overlay {
  color: #FFF;
  font-size: 16px;
  display: block;
  padding: 0px 35px 0px 35px;
  position: absolute;
  bottom: 30px;
  right: 0;
  left: 0;
  text-align: center;
}

.slimslider-title {
  display: block;
  font-size: 18px;
  font-weight: bold;
  text-shadow: 0 0 10px rgba(0, 116, 181, 1);
}

.slimslider-text {
  display: block;
  font-size: 14px;
  text-shadow: 0 0 10px rgba(0, 116, 181, 1);
}

.newsletter {
  background-color: $brand-primary;
  padding: 35px 0 40px 0;
  text-align: center;
}

.newsletter h2 {
  color: #FFF;
  margin-bottom: 10px;
}

.newsletter p {
  color: #FFF;
  margin-bottom: 20px;
}

.newsletter input {
  border: none;
  height: 44px;
  padding: 0 10px;
  width: 100%;
  text-align: center;
}

.newsletter button {
  border: 1px solid #FFF;
  color: #FFF;
  background: #3390c4;
  width: 100%;
  height: 32px;
}

.multiple-videos {
  margin-top: 100px;
}

.multiple-videos h2 {
  margin-bottom: 30px;
}

.contactform {
  //margin-bottom: 100px;
}

.contactform__wrapper {
  background: #f1f1f1;
  padding: 50px 15px 100px 15px;
  margin: 0 -15px;
}

//Cookie-Hinweis
.cc_container {
  border:0!important;
  box-shadow: 0 0 5px rgba(0,0,0,0.5)!important;
}
.cc_message {
  line-height: 22px!important;
  font-size: 0.95em!important;
  color:#4c4c4c!important;
  margin-top:0!important;
}
.cc_message a {
  color: #5E5E5D !important;
  text-decoration: underline !important;
}
.cc_btn {
  background-color: $brand-primary!important;
  border-radius: 0 10px 10px 0!important;
  color:#FFF!important;
  font-size: 0.95em!important;
  width:23% !important;
  padding: 0!important;
  height: 44px!important;
  line-height: 44px!important;
}
.cc_container ::-moz-selection {
  //background:$brand-primary-bg !important;
}

.techdata {
  padding: 50px 0;
}

#content {
  overflow: hidden;
}

#content .techdata .nav-tabs li {
  margin: 0 0 -1px 0;
  padding: 0;
}

#content .techdata ul.nav-tabs li:before {
  content: none!important;
}

.techdata ul.nav-tabs {
  border-bottom: none;
  margin: 0!important;
}

.techdata ul.nav-tabs li a {
  border-radius: 0;
  border: 1px solid #ddd;
  border-bottom: none;
  color: #727272;
}

.techdata ul.nav-tabs li a:hover {
  border-bottom: none;
}

.techdata ul.nav-tabs li.active {
  z-index: 2;
}

.techdata ul.nav-tabs li.active a {
  //background: $brand-primary;
  border: 1px solid #727272;
  border-bottom: none;
  color: #555;
}

.techdata .tab-content {
  position: relative;
  margin: 0;
  padding: 10px;
  border: 1px solid #727272;
  overflow: hidden;
  z-index: 1;
}

.techdata .tab-content table {
  width: 100%;
}

#tinymce table {
  width: 100%;
}

.table-responsive__wrapper {
  margin: 50px 0;
}

.table-responsive {
  margin-bottom: 30px;
}

.table-responsive table {
  width: 100%;
}

.table-responsive__wrapper h3 {
  margin: 30px 0 20px 0;
}

.table-responsive table tr td {
  padding: 10px;
  border: 1px solid #ddd;
}

.table-responsive table tr:first-of-type td {
  background-color: #727272;
  color: #FFF;
  font-weight: 700;
}

.table-responsive table tr:nth-of-type(even) td {
  background-color: #eee;
}

.table-responsive table>tbody>tr>td, .table-responsive table>tbody>tr>th, .table-responsive table>tfoot>tr>td, .table-responsive table>tfoot>tr>th, .table-responsive table>thead>tr>td, .table-responsive table>thead>tr>th {
  white-space: nowrap;
}

.listboxes-slider {
  margin: 50px 0 0;
}

.listboxes-slider .row {
  margin: 30px 0;
}

.navboxes {
  position: relative;
  //background-color: #FFF;
  margin: 0 auto 50px auto;
  padding: 20px 0 0 0;
}

.navboxes-inner {
  text-align: center;
  margin: 0 -15px;
}

.navboxes-item {
  display: inline-block;
  position: relative;
  width: 150px;
  height: 165px;
  margin: 0 15px 30px 15px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.navboxes-item span {
  display: block;
  min-width: 50%;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: transparentize($brand-primary,0.2);
  font-size: 16px;
  color: #FFF;
  padding: 6px 15px;
  transition: all 0.3s;
  text-align: left;
}

.navboxes-item:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: transparentize($brand-primary,0.5);
  transition: all 0.3s;
}

.navboxes-item:hover:before {
  width: 100%;
  height: 100%;
}

.navboxes-item:hover span {
  font-size: 20px;
}

.ytvideo-play {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.ytvideo-play span.fa {
  font-size: 90px;
  display: block;
  text-align: center;
}

.ytvideo-close {
  position: absolute;
  font-size: 25px;
  color: #ccc;
  right: 10px;
  top: 10px;
  line-height: 1;
  padding: 10px;
  cursor: pointer;
}

/*** Contactpersons ***/

//.contactpersons {
//  margin: 40px 0;
//}
//
//.contactpersons-inner {
//  margin-left: -15px;
//  margin-right: -15px;
//  text-align: center;
//}
//
//.contactpersons-item {
//  display: inline-block;
//  position: relative;
//  margin: 0 15px;
//  overflow: hidden;
//}
//
//.contactpersons-label {
//  display: block;
//  position: absolute;
//  bottom: 0;
//  left: 0;
//  right: 0;
//  background-color: $brand-primary;
//  color: #fff;
//  padding: 6px 12px;
//  font-size: 16px;
//  -webkit-transition: all .3s;
//  transition: all .3s;
//}
//
//.contactpersons-item:hover .contactpersons-label {
//  font-size: 20px;
//}
//
//.contactpersons-text {
//  position: absolute;
//  bottom: 0;
//  left: 0;
//  height: 0;
//  width: 0;
//  background-color: rgba(0, 116, 181, 0.65);
//  color: #fff;
//  text-align: left;
//  -webkit-transition: all .3s;
//  transition: all .3s;
//}
//
//.contactpersons-item:hover .contactpersons-text {
//  height: 100%;
//  width: 100%;
//  padding: 20px;
//}
//
//.contactpersons-text a,
//.contactpersons-text a:hover,
//.contactpersons-text a:focus {
//  color: #fff;
//}

.certificates {
  margin: 0 0 50px 0;
}

.certificates__item {
  display: block;
  margin-bottom: 50px;
  text-align: center;
  font-size: 13px;
}

.certificates__item img {
  border: 2px solid #f1f1f1;
  max-width: 135px;
  max-height: 135px;
  padding: 5px;
  margin: 0 auto 10px auto;
}

.flex-center-row {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}
.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  /* ideally, transition speed should match zoom duration */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}
.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}
@media (max-width: 767px) {

  .techdata .tab-content table td {
    display: block;
    padding: 5px 5px 0 5px;
  }

  .techdata .tab-content table td:first-of-type {
    font-weight: bold;
  }

  .techdata .tab-content table td:last-of-type {
    padding-bottom: 10px;
  }

}



/* Kleine Geräte (Tablets, 768px und breiter) */
@media (min-width: $screen-tablet) {

  .certificates__item img {
    max-width: 165px;
    max-height: 165px;
    padding: 10px;
  }

  .techdata .tab-content table td  {
    border: 1px solid #ddd;
    padding: 10px;
  }

  .techdata .tab-content table td:first-of-type  {
    width: 40%;
  }

  .techdata .tab-content table tr:nth-of-type(even) td  {
    background-color: #eee;
  }

  header {
    //height: 185px;
    height: auto;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 200;
    background-color: #fff;
  }

  .header {
    height: 145px;
  }

  .logo {
    float: left;
    padding: 0;
    width: 231px;
    height: 102px;
    max-height: none;
    margin-top: 20px;
  }

  .logo-container {
    display: block;
  }

  .logo-full,
  .logo-alone {
    display: block;
    position: absolute;
    top: 0;
  }

  .logo-full {
    opacity: 1;
    height: 102px;
  }

  .logo-alone {
    opacity: 0;
    height: 83px;
  }

  .logo img {
    //width: 231px;
    //height: 102px;
    max-height: 100%;
    width: 100%;
  }

  .nav-top {
    height: auto;
    background-color: #727272;
  }

  nav ul li {
    position: static;
  }
  
  .nav0-1 {
    display: block !important;
    position: relative;
    top: inherit;
    padding: 0;
  }

  .nav0-1 a {
    margin-right: 0;
  }

  .nav0-1 > ul {
    position: relative;
  }

  .nav0-1 > ul > li {
    float: left;
    text-align: center;
    transition: all 0.3s;
  }

  .nav0-1 > ul > li a.active {
    background-color: $brand-primary;
  }

  .nav0-1 > ul > li a {
    font-size: 14px;
    padding: 11px 10px 10px 10px;
    height: 40px;
  }

  .nav0-1 > ul > li.active,
  .nav0-1 > ul > li:hover {
    background-color: $brand-primary;
  }

  .nav0-1 > ul > li.active.sub {
    margin-bottom: 40px;
  }

  .nav0-1 > ul > li.active .nav0-2,
  .nav0-1 > ul > li:hover .nav0-2 {
    //display: block;
    visibility: visible;
    transition-delay: 0.1s;
  }

  .nav0-1 > ul > li.active .nav0-2 {
    z-index: 0;
    transition-delay: 0.1s;
  }

  .nav0-2 {
    display: block;
    position: absolute;
    width: 100%;
    background-color: $brand-primary;
    left: 0;
    height: 40px;
    visibility: hidden;
    transition: visibility 0.1s 0.2s;
    z-index: 1;
  }

  .nav0-2:before {
    content: "";
    position: absolute;
    height: 40px;
    background: $brand-primary;
    right: 720px;
    width: 2000px;
    display: block;
  }

  .nav0-2:after {
    content: "";
    position: absolute;
    height: 40px;
    background: $brand-primary;
    left: 720px;
    width: 2000px;
    display: block;
  }

  .nav0-2 > ul > li > a {
    color: #FFF;
    height: 40px;
  }

  .nav0-2 > ul > li:hover,
  .nav0-2 > ul > li a.active {
    background-color: #3290C4;
  }

  .nav0-2 > ul > li {
    float: left;
  }

  .nav0-3 > ul > li {
    float: left;
  }

  .nav0-4 > ul > li {
    float: left;
  }

  .nav0-3 {
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    background: #3290C4;
    visibility: hidden;
    transition: 0.1s 0.2s;
  }

  .nav0-3:before {
    content: "";
    position: absolute;
    height: 40px;
    background: #3290C4;
    right: 720px;
    width: 2000px;
    display: block;
  }

  .nav0-3:after {
    content: "";
    position: absolute;
    height: 40px;
    background: #3290C4;
    left: 720px;
    width: 2000px;
    display: block;
  }

  .nav0-4 {
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    background: lighten(#3290C4,5%);
    visibility: hidden;
    transition: 0.1s 0.2s;
  }

  .nav0-4:before {
    content: "";
    position: absolute;
    height: 40px;
    background: lighten(#3290C4,5%);
    right: 720px;
    width: 2000px;
    display: block;
  }

  .nav0-4:after {
    content: "";
    position: absolute;
    height: 40px;
    background: lighten(#3290C4,5%);
    left: 720px;
    width: 2000px;
    display: block;
  }

  .nav0-2 > ul > li:hover .nav0-3 {
    //display: block!important;
    visibility: visible;
    transition-delay: 0.1s;
  }

  .nav0-3 > ul > li:hover {
    background-color: lighten(#3290C4,5%);
  }

  .nav0-4 > ul > li:hover {
    background-color: lighten(#3290C4,10%);
  }

  .nav0-3 > ul > li:hover .nav0-4 {
    //display: block;
    visibility: visible;
    transition-delay: 0.1s;
  }

  .global ul li {
    float: left;
    list-style-type: none;
    padding: 0;
  }

  .global ul li a {
    position: relative;
  }

  .global .lang-select {
    margin: 20px 0 0 50px;
  }

  .global .contact {
    color: $brand-gray;
    margin-top: 20px;
    float: right;
    line-height: 13px;
  }

  .global .contact a {
    color: $brand-gray;
  }

  .global .contact a:hover {
    color: $brand-primary;
  }

  .global .contact li:not(:last-of-type) {
    margin-right: 20px;
  }

  .global .contact i.fa {
    color: #000;
    margin-right: 5px;
  }

  .global .social-icons {
    position: absolute;
    top: 44px;
    right: 15px;
    font-size: 24px;
  }

  .global .social-icons a {
    display: block;
    transition: all 0.2s;
  }

  .global .social-icons a:hover {
    color: $brand-primary!important;
    transform: scale(1.2);
  }

  .global .social-icons li:not(:last-of-type) a {
    margin-right: 10px;
  }

  .global .social-icons .fa-youtube-square {
    color: #cd201f;
  }

  .global .social-icons a:hover .fa-youtube-square {
    color: #cd201f;
  }

  .search {
    position: absolute;
    top: -55px;
    right: 0;
  }

  .search form {
    margin: 0;
    color: $brand-gray;
  }

  .search input {
    background: #f1f1f1;
    height: 33px;
    width: 270px;
    text-align: left;
  }

  .search--btn {
    right: 0;
    width: 32px;
    height: 32px;
    font-size: 16px;
    padding-right: 48px;
  }

  .search--btn:before {
    content: "";
    display: block;
    height: 12px;
    width: 1px;
    background-color: $brand-gray;
    position: absolute;
    left: -8px;
    top: 11px;
  }

  .search .contact {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
  }

  .search .contact .dropdown,
  .search .contact > .btn {
    display: block;
    float: left;
    margin-right: 5px;
  }

  .search .contact .dropdown-menu {
    min-width: 100%;
    border-radius: 0;
    top: 32px;
    background-color: #0074b5;
  }

  .search .contact .dropdown-menu a {
    font-size: inherit;
    padding: 3px 12px;
  }

  .search .contact .btn {
    padding: 6px 12px;
    font-size: inherit;
    border: none;
    height: 33px;
    margin-top: 0;
  }

  .search .contact .btn,
  .search .contact .btn:focus,
  .search .contact .btn:active {
    outline: none;
    color: #fff;
  }

  .slider--item {
    height: 360px;
  }

  .slider-overlay {
    font-size: 24px;
    bottom: 75px;
    opacity: 0;
    transition: opacity 0.6s 0.4s;
  }

  .slick-active .slider-overlay {
    opacity: 1;
  }

  .slick-dots {
    bottom: 25px;
  }

  .listboxes-slider .slick-dots {
    bottom: 0;
  }

  .slider .slick-arrow {
    transition: all 0.2s;
  }

  .slider .slick-arrow:hover {
    //transform: scale(2);
  }

  .cboxes {
    //max-width: 750px;
    padding: 0;
  }

  .cboxes-inner {
    max-width: 750px;
    //border-top: 20px solid #FFF;
    //padding: 0 15px;
  }

  .slimslider + .cboxes {
    margin-top: 50px;
  }

  .slider + .cboxes .cboxes-inner {
    margin-top: -30px;
  }

  .cboxes-item {
    height: 165px;
    margin-bottom: 15px;
  }

  .slider + .cboxes .cboxes-item {
    margin-top: -10px;
  }

  #content.start {
    background-image: url('../img/background.png');
    background-repeat: repeat-y;
    background-size: 100%;
  }

  .listboxes-text {
    color: #000;
    background-color: #FFF;
    border: 2px solid $brand-primary;
    width: 75%;
    padding-right: 25%;
    padding-left: 20px;
    padding-bottom: 20px;
  }

  .listboxes-text h2 {
    color: $brand-primary;
  }

  .listboxes .row:nth-of-type(even) .listboxes-text {
    color: #FFF;
    background-color: $brand-primary;
    margin-left: 25%;
    padding-right: 20px;
    padding-left: 25%;
  }

  .listboxes .row:nth-of-type(even) .listboxes-text h2 {
    color: #FFF;
  }

  .listboxes .row:nth-of-type(even) .listboxes-text .btn {
    background-color: #FFF;
    color: $brand-primary;
  }

  .listboxes-image {
    position: absolute;
    width: 48%;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 185px;
  }

  .listboxes .row:nth-of-type(even) .listboxes-image {
    right: auto;
    left: 0;
  }

  .footer-nav {
    float: right;
    margin-right: -20px;
  }

  .footer-nav li {
    float: left;
    line-height: 14px;
  }

  .footer-nav li:not(:last-of-type) {
    border-right: 1px solid #FFF;
  }

  .footer-nav li:last-of-type {
    padding-right: 0;
  }

  .footer-nav li a {
    padding: 0 20px;
  }

  .slimslider--item {
    background-position: 40% center;
    background-repeat: no-repeat;
    width: 100%;
    height: 300px;
    overflow: hidden;
  }

  .slimslider-overlay {
    left: 55%;
    width: 45%;
    text-align: left;
  }

  .slimslider-title {
    font-size: 24px;
  }

  .slimslider-text {
    font-size: 16px;
  }

  .slimslider .slick-dots {
    bottom: 0;
  }

  .newsletter__form {
    width: 570px;
    margin: 0 auto;
  }

  .newsletter input {
    width: 370px;
    float: left;
    height: 30px;
  }

  .newsletter button {
    height: 30px;
    width: 200px;
  }

  .contactform__wrapper {
    margin: 0;
  }

  .techdata .tab-content {
    padding: 30px 20px;
  }

  .navboxes {
    padding: 30px 0 0 0;
  }


}

/* Mittlere Geräte (Desktop-PCs, 992px und breiter) */
@media (min-width: $screen-desktop) {

  .nav0-1 > ul > li a {
    padding: 11px 20px 10px 20px;
  }

  .global {
    margin-top: 35px;
  }

  .global-inner {
    float: right;
  }

  .global .lang-select {
    margin-top: 10px;
  }

  .global .contact {
    float: left;
    margin-top: 10px;
  }

  .global .contact ul {
    padding: 0;
  }

  .global .contact li {
    margin: 0 0 0 40px!important;
  }

  .global .social-icons {
    position: relative;
    top: auto;
    right: auto;
    float: left;
  }

  .cboxes-inner {
    max-width: 970px;
    //border-top: 40px solid #FFF;
    //padding: 0 15px;
  }

  .slider + .cboxes .cboxes-inner {
    margin-top: -40px;
  }

  .cboxes-item {
    height: 210px;
  }

  .slider + .cboxes .cboxes-item {
    margin-top: -20px;
  }

  .cboxes-item span {
    font-size: 18px;
  }

  .cboxes-item:hover span {
    font-size: 22px;
  }

  .slider--item {
    height: 420px;
  }

  .slick-dots {
    bottom: 40px;
  }

  .listboxes-slider .slick-dots {
    bottom: 0;
  }

  .slider-overlay {
    font-size: 24px;
  }

  .listboxes-item {
    min-height: 200px;
  }

  .listboxes-image {
    height: 240px;
  }

  .listboxes-text {
    padding-left: 20px;
  }

  .nav0-2:before,
  .nav0-3:before,
  .nav0-4:before {
    right: 940px;
  }

  .nav0-2:after,
  .nav0-3:after,
  .nav0-4:after {
    left: 940px;
  }

  .slimslider--item {
    background-position: center center;
  }

  .slimslider-title {
    font-size: 30px;
  }

  .slimslider-text {
    font-size: 24px;
  }

  .techdata .tab-content {
    padding: 40px 20px;
  }

  .contactform__wrapper form {
    margin: 0 auto;
    max-width: 60%;
  }

}

/* Große Geräte (Desktop-PCs, 1200px und breiter) */
@media (min-width: $screen-lg-desktop) {

  .nav0-1 > ul > li a {
    padding: 11px 30px 10px 30px;
  }

  .slider--item {
    height: 520px;
  }

  .slick-dots {
    bottom: 45px;
  }

  .listboxes-slider .slick-dots {
    bottom: 0;
  }

  .cboxes-inner {
    width: 1170px;
    max-width: none;
    //border-top: 60px solid #FFF;
    //padding: 0 15px;
  }

  .slider + .cboxes .cboxes-inner {
    margin-top: -60px;
  }

  .cboxes-item {
    height: 270px;
    margin-bottom: 25px;
  }

  .slider + .cboxes .cboxes-item {
    margin-top: -36px;
  }

  .slider-overlay {
    bottom: 100px;
    font-size: 30px;
  }

  .listboxes-item {
    margin-bottom: 140px;
  }

  .listboxes-slider .listboxes-item {
    margin-bottom: 90px;
  }

  .listboxes-text {
    width: 885px;
    min-height: 300px;
    padding: 30px 315px 30px 30px
  }

  .listboxes-image {
    width: 570px;
    min-height: 300px;
    top: 30px;
    transform: none;
  }

  .listboxes .row:nth-of-type(even) .listboxes-image {
    top: -30px;
  }

  .listboxes .row:nth-of-type(even) .listboxes-item {
    margin-bottom: 80px;
  }

  .listboxes-slider .row:nth-of-type(even) .listboxes-item {
    margin-bottom: 30px;
  }

  .listboxes .row:nth-of-type(even) .listboxes-text {
    padding: 30px 30px 30px 310px;
    margin-left: 285px;
  }

  .listboxes .row:last-of-type .listboxes-item {
    margin-bottom: 50px;
  }

  .listboxes-slider .row-of-type .listboxes-item {
    margin-bottom: 0px;
  }

  .nav0-2:before,
  .nav0-3:before,
  .nav0-4:before {
    right: 1170px;
  }

  .nav0-2:after,
  .nav0-3:after,
  .nav0-4:after {
    left: 1170px;
  }

  .slimslider-overlay {
    width: 40%;
  }

  .slick-prev {
    right: 0;
    left: 50%;
    margin-left: -600px;
  }

  .slick-next {
    right: 0;
    left: 50%;
    margin-left: 555px;
  }

}

@media (min-width: 1440px) {

  .navboxes-inner {
    width: 1440px;
    margin: 0 -135px;
  }

}
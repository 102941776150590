/*centering*/
.contactpersons {
  float: right;
  right: 50%;
  position: relative;
}

.contactpersons__inner {
  float: right;
  right: -50%;
  position: relative;
}
/*centering end*/

.contactpersons__item {
  margin-bottom: 20px;
  float: left;
  max-width: 150px;
  min-width: 150px;
  width: 50%;
  position: relative;
  //min-height: 248px;
}

.contactpersons__item:not(:last-child){
  margin-right: 20px;
}

//.contactpersons__item-img {
//  height: 200px;
//  background-size: auto;
//  background-repeat: no-repeat;
//  background-position: center;
//}

.contactpersons__item-name {
  padding: 5px 10px;
  background-color: #0074b5;
  color: #fff;
  z-index: 2;
  position: relative;
}

.contactpersons__item-desc {
  display: block;
  position: absolute;
  visibility: hidden;
  width: 0;
  height: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,116,181,.5);
  -webkit-transition: all .3s;
  transition: all .3s;
  color: #fff;
  padding: 10px;
  z-index: 1;
}

.contactpersons__item-mail {
  color: #fff;
  text-decoration: underline;
  &:hover {
    color: #fff;
    text-decoration: underline;
  }
}

.contactpersons__item:hover {
  .contactpersons__item-desc {
    width: 100%;
    height: 100%;
    visibility: visible;
  }
}

@media (min-width: 768px) {
  .contactpersons__item {
    width: 25%;
  }
}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}